<template>
  <div>

    <BaseHeader :title="'Earn From Refferals'"></BaseHeader>

  </div>
</template>

<script>
export default {
  name: "EarnFromRefferals",
};
</script>
